import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';
import ArticleCard from '../components/card/Article';

const PressPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/presse-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "backgrounds/presse-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      articles: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/articles/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___date, order: DESC}
      )
      {
        nodes {
          frontmatter {
            title
            description
            publisher
            type
            link
            image {
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
          body
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  const backgroundImage = query.backgroundImage.childImageSharp.fluid

  return (
    <Layout 
      pageTitle={`Presse | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription={`Découvrez tous les articles sur ${query.site.siteMetadata.title}. Contactez-nous si vous avez besoin de plus d'informations et souhaitez écrire un article.`}
      pageKeywords="pcc bâtiment, roanne, coteau, loire, presse, article, le ptit roannais, le bruit qui court"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Presse</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Presse</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {query.articles && query.articles.nodes.length ?
        <BackgroundImage
          Tag="section"
          fluid={backgroundImage}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                {query.articles.nodes.map(article => (
                  <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                ))}
              </div>
            </div>
          </div>
        </BackgroundImage>
      : null}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="2" />
    </Layout>
  )
}

export default PressPage
